/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $GenericField = {
  properties: {
    id: {
      type: 'string',
      isRequired: true,
    },
    label: {
      type: 'string',
      isRequired: true,
    },
    order: {
      type: 'number',
      isRequired: true,
      format: 'int16',
    },
    groupId: {
      type: 'string',
      isRequired: true,
    },
    type: {
      type: 'string',
      isRequired: true,
    },
    value: {
      properties: {
      },
      isRequired: true,
    },
  },
} as const;
