/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ListContractsByCompanyRequest = {
  properties: {
    search: {
      type: 'string',
    },
    sorting: {
      type: 'string',
    },
    limit: {
      type: 'number',
      isRequired: true,
    },
    offset: {
      type: 'number',
      isRequired: true,
    },
    filter: {
      properties: {
        status: {
          type: 'array',
          contains: {
            type: 'string',
          },
        },
      },
    },
  },
} as const;
