/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ReportData = {
  properties: {
    groups: {
      type: 'array',
      contains: {
        type: 'GroupFields',
      },
      isRequired: true,
    },
    fields: {
      type: 'dictionary',
      contains: {
        type: 'GenericField',
      },
      isRequired: true,
    },
  },
} as const;
