/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $SyncError = {
  properties: {
    id: {
      type: 'string',
      isRequired: true,
    },
    task: {
      type: 'string',
      isRequired: true,
    },
    error: {
      properties: {
        groupId: {
          type: 'string',
        },
        documentName: {
          type: 'string',
        },
        documentType: {
          type: 'string',
        },
        fileName: {
          type: 'string',
        },
      },
      isRequired: true,
    },
  },
} as const;
