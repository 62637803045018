/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum BiometricsResultStatus {
  VALID = 'VALID',
  INVALID = 'INVALID',
  MANUAL_REPROVAL = 'MANUAL_REPROVAL',
  MANUAL_APPROVAL = 'MANUAL_APPROVAL',
}
