/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ListSyncErrorsResponse = {
  properties: {
    professionalId: {
      type: 'string',
      isRequired: true,
    },
    errors: {
      type: 'array',
      contains: {
        type: 'SyncError',
      },
      isRequired: true,
    },
  },
} as const;
