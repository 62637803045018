/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $GetPersonBackgroundCheckResponse = {
  properties: {
    rfPfReport: {
      type: 'ReportData',
      isRequired: true,
    },
    validationResults: {
      type: 'array',
      contains: {
        type: 'AdminValidationResult',
      },
      isRequired: true,
    },
    title: {
      type: 'string',
      isRequired: true,
    },
  },
} as const;
