/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ProfessionalSyncStatus {
  UNABLE = 'UNABLE',
  ABLE = 'ABLE',
  SENT = 'SENT',
  FAILED = 'FAILED',
  SUCCESSFUL = 'SUCCESSFUL',
  PARTIALLY_CREATED = 'PARTIALLY_CREATED',
}
