/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $CompanyContractInfo = {
  properties: {
    cnpj: {
      type: 'string',
    },
    company: {
      type: 'array',
      contains: {
        properties: {
          id: {
            type: 'string',
            isRequired: true,
          },
          mainName: {
            type: 'string',
            isRequired: true,
          },
          personId: {
            type: 'string',
            isRequired: true,
          },
        },
      },
    },
    contracts: {
      type: 'array',
      contains: {
        properties: {
          id: {
            type: 'string',
            isRequired: true,
          },
          templateName: {
            type: 'string',
            isRequired: true,
          },
          status: {
            type: 'string',
            isRequired: true,
          },
          hirers: {
            type: 'array',
            contains: {
              properties: {
                hirerId: {
                  type: 'string',
                  isRequired: true,
                },
                hirerName: {
                  type: 'string',
                  isRequired: true,
                },
                hirerCompanyName: {
                  type: 'string',
                  isRequired: true,
                },
              },
            },
            isRequired: true,
          },
          signers: {
            type: 'array',
            contains: {
              properties: {
                name: {
                  type: 'string',
                  isRequired: true,
                },
                signatureStatus: {
                  type: 'string',
                  isRequired: true,
                },
              },
            },
          },
        },
      },
    },
    professionals: {
      type: 'array',
      contains: {
        properties: {
          name: {
            type: 'string',
            isRequired: true,
          },
          cpf: {
            type: 'string',
            isRequired: true,
          },
          crm: {
            type: 'string',
            isRequired: true,
          },
          crmUF: {
            type: 'string',
            isRequired: true,
          },
          qualification: {
            type: 'string',
            isRequired: true,
          },
        },
      },
    },
  },
} as const;
